import React, { useEffect, useRef, useState } from "react";
import { useWatch } from "react-hook-form";
import useClickOutSide from "../../hooks/useClickOutSide";

const Dropdown = ({
  control,
  setValue,
  name = "job",
  data,
  dropdownLabel = " ",
  defaultValue = "",
}) => {
  const { isOpen, setIsOpen, nodeRef } = useClickOutSide("button");
  const dropdownValue = useWatch({
    control,
    name: name,
    defaultValue,
  });
  const handleClickDropdownItem = (e) => {
    setValue(name, e.target.dataset.value);
    setIsOpen(false);
    setLabel(e.target.textContent);
  };
  const [label, setLabel] = useState(dropdownLabel);
  useEffect(() => {
    if (dropdownValue === "") setLabel(dropdownLabel);
    if (dropdownValue !== "") {
      setValue(name, dropdownValue);
      const selectedLanguage = data.find(
        (language) => language.value === dropdownValue
      );
      if (selectedLanguage && selectedLanguage.text) {
        setLabel(selectedLanguage.text);
      }
    }
  }, [dropdownValue, dropdownValue]);

  return (
    <div className="relative" ref={nodeRef}>
      <div
        className="flex items-center justify-between p-5 bg-white border border-gray-100 rounded-lg cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <button>{label}</button>
      </div>
      <div
        className={`absolute top-full left-0 w-full rounded-lg bg-white ${
          isOpen ? "" : "opacity-0 invisible"
        }`}
      >
        {data.map((item) => (
          <div
            className="p-5 cursor-pointer hover:bg-gray-100"
            data-value={item.value}
            onClick={handleClickDropdownItem}
            key={item.id}
          >
            {item.text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
