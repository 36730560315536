import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import TableProjectDetail from "../components/table/TableProjectDetail";
import useSWR from "swr";
import { fetcher, snapUserAPI } from "../config";
import { Link } from "react-router-dom";

const ProjectDetailPage = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { data, error, isLoading } = useSWR(
    snapUserAPI.getProject(projectId),
    fetcher
  );
  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {data && <h1 className="text-2xl">Dự án: {data.data.name}</h1>}

      <Link
        to={`/keyword/add/${projectId}`}
        className="inline-block px-3 py-2 mt-4 text-white bg-blue-500 rounded"
      >
        Thêm từ khóa
      </Link>
      {data && data.data.campaigns && data.data.campaigns.length > 0 && (
        <TableProjectDetail
          campaigns={data.data.campaigns}
        ></TableProjectDetail>
      )}
      {data && data.data.campaigns.length === 0 && (
        <div className="mt-4">Dự án chưa có từ khóa nào</div>
      )}
    </div>
  );
};

export default ProjectDetailPage;
