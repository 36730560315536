import React, { useEffect } from "react";
import Input from "../components/input/Input";
import { useNavigate, useParams } from "react-router-dom";
import { fetcher, languages, schemaCampaign, snapUserAPI } from "../config";
import useSWR, { mutate } from "swr";
import Dropdown from "../components/dropdown/Dropdown";
import Required from "../components/template/Required";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
// import axios from "axios";

const EditKeywordPage = () => {
  const { projectId, campaignId } = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors, isValid, isSubmitting, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(schemaCampaign),
    mode: "onChange",
  });

  //   Fetch Data
  const {
    data: campaignData,
    error: error,
    isLoading: isLoading,
    mutate: mutate,
  } = useSWR(snapUserAPI.getAndUpdateCampaign(projectId, campaignId), fetcher);

  // Fix load old data
  useEffect(() => {
    mutate();
  }, [{ projectId, campaignId, mutate }]);

  if (!campaignData) return null;
  const campaign = campaignData.data;
  console.log(campaign);
  //   handleSubmit
  const onSubmit = (data) => {
    if (!isValid) return;
    console.log("data", data);
    axios
      .put(snapUserAPI.getAndUpdateCampaign(projectId, campaignId), data)
      .then(function (response) {
        if (response.data.status === 0) {
          console.log("Chỉnh sửa thành công");
          toast.success("Chỉnh sửa từ khóa thành công");
          navigate(`/project/${projectId}`);
        } else {
          toast.error("Chỉnh sửa từ khóa thất bại");
        }
      })
      .catch(function (error) {
        toast.error("Chỉnh sửa từ khóa thất bại");
      });
  };
  return (
    <div>
      {campaign && (
        <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col gap-1">
              <label htmlFor="keyword" className="cursor-pointer">
                Từ khóa <Required />
              </label>
              <Input
                type="text"
                name="keyword"
                id="keyword"
                placeholder="Nhập từ khóa"
                control={control}
                defaultValue={campaign.keyword || ""}
              ></Input>

              {errors.keyword && (
                <p className="text-sm italic text-red-500">
                  {errors.keyword.message}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="website" className="cursor-pointer">
                website <Required />
              </label>
              <Input
                type="text"
                name="website"
                id="website"
                placeholder="Enter website"
                control={control}
                defaultValue={campaign.website || ""}
              ></Input>

              {errors.website && (
                <p className="text-sm italic text-red-500">
                  {errors.website.message}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="image_url" className="cursor-pointer">
                Hình ảnh <Required />
              </label>
              <Input
                type="text"
                name="image_url"
                id="image_url"
                placeholder="Nhập link hình ảnh"
                control={control}
                defaultValue={campaign.image_url || ""}
              ></Input>

              {errors.image_url && (
                <p className="text-sm italic text-red-500">
                  {errors.image_url.message}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="mobile_image_url" className="cursor-pointer">
                Hình ảnh mobile <Required />
              </label>
              <Input
                type="text"
                name="mobile_image_url"
                id="mobile_image_url"
                placeholder="Nhập link hình ảnh mobile"
                control={control}
                defaultValue={campaign.mobile_image_url || ""}
              ></Input>

              {errors.mobile_image_url && (
                <p className="text-sm italic text-red-500">
                  {errors.mobile_image_url.message}
                </p>
              )}
            </div>

            <div className="flex flex-col gap-2 mt-4">
              <label htmlFor="language" className="cursor-pointer">
                Ngôn ngữ - Local hiển thị <Required />
              </label>
              <Dropdown
                control={control}
                setValue={setValue}
                name="language"
                data={languages}
                dropdownLabel="Country"
                defaultValue={campaign.language || ""}
              ></Dropdown>

              {errors.language && (
                <p className="text-sm italic text-red-500">
                  {errors.language.message}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="priority" className="cursor-pointer">
                Độ ưu tiên <Required />
              </label>
              <Input
                type="number"
                name="priority"
                id="priority"
                placeholder="Enter priority"
                control={control}
                defaultValue={campaign.priority || ""}
              ></Input>

              {errors.priority && (
                <p className="text-sm italic text-red-500">
                  {errors.priority.message}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="max_action" className="cursor-pointer">
                Số lượng traffic <Required />
              </label>
              <Input
                type="number"
                name="max_action"
                id="max_action"
                placeholder="Nhập số lượng traffic"
                control={control}
                defaultValue={campaign.max_action || ""}
              ></Input>

              {errors.max_action && (
                <p className="text-sm italic text-red-500">
                  {errors.max_action.message}
                </p>
              )}
            </div>
          </div>
          <button
            type="submit"
            className={`p-4 w-full bg-blue-500 text-white rounded-md mt-5 font-semibold flex justify-center items-center ${
              isSubmitting ? "opacity-50" : ""
            }`}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <div className="w-5 h-5 border-2 border-white rounded-full border-r-transparent animate-spin"></div>
            ) : (
              "Cập nhật"
            )}
          </button>
        </form>
      )}
    </div>
  );
};

export default EditKeywordPage;
