import React from "react";

import IconView from "./IconView";
import IconEdit from "./IconEdit";
import IconRemove from "./IconRemove";
import IconAdd from "./IconAdd";
import { fetcher, snapUserAPI } from "../../config";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";

const TableProjectDetail = ({ campaigns }) => {
  console.log(campaigns);
  const navigate = useNavigate();
  // console.log(id);
  // const { data, error, isLoading } = useSWR(
  //   snapUserAPI.getProject(id),
  //   fetcher
  // );
  //   const { campaigns } = data?.data;
  return (
    <div className="prose">
      {campaigns.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>STT</th>
              <th>Từ khóa</th>
              <th>Ngôn ngữ</th>
              <th>Độ ưu tiên</th>
              <th>Sl Real/Max</th>
              <th>Volume max</th>
              <th>Thao tác</th>
            </tr>
          </thead>
          <tbody>
            {campaigns.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.keyword}</td>
                <td>{item.language}</td>
                <td>{item.priority}</td>
                <td>{`${item.completed_action} / ${item.max_action}`}</td>
                <td>{item.max_action}</td>
                <td className="flex items-center gap-2">
                  <button
                    onClick={() =>
                      navigate(`/keyword/edit/${item.project_id}/${item._id}`)
                    }
                  >
                    <IconEdit></IconEdit>
                  </button>
                  <button
                    onClick={() => alert("Tính năng đang được phát triển")}
                  >
                    <IconRemove></IconRemove>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {campaigns.length === 0 && <div>Dự án chưa có từ khóa nào</div>}
    </div>
  );
};

export default TableProjectDetail;
