import * as yup from "yup";
export const fetcher = (...args) => fetch(...args).then((res) => res.json());

const endpoint = "https://snapuser.snapx.info/api";

export const snapUserAPI = {
  projects: () => `${endpoint}/projects/v1/`,
  getProject: (id) => `${endpoint}/projects/v1/${id}/`, // Get and Update
  getCampaigns: () => `${endpoint}/campaigns/v1/`,
  createKeyword: (project_id) =>
    `${endpoint}/projects/v1/${project_id}/campaigns/`,
  getAndUpdateCampaign: (project_id, campaignId) =>
    `${endpoint}/projects/v1/${project_id}/campaigns/${campaignId}/`,
};

export const languages = [
  { id: 1, text: "United States", value: "US" },
  { id: 2, text: "Việt Nam", value: "VN" },
  { id: 3, text: "Indonesia", value: "ID" },
  { id: 4, text: "India", value: "IN" },
  { id: 5, text: "Thổ Nhỹ Kỳ", value: "TR" },
  { id: 6, text: "Malaysia", value: "MY" },
  { id: 7, text: "Philippines", value: "PH" },
  { id: 8, text: "Thái Lan", value: "TH" },
];

export const schemaCampaign = yup
  .object({
    keyword: yup
      .string()
      .required("Vui lòng nhập từ khóa")
      .min(5, "Từ khóa phải có tối thiểu 5 ký tự")
      .max(50, "Từ khóa không được dài hơn 50 ký tự"),
    website: yup.string().required("Vui lòng nhập website"),
    image_url: yup
      .string()
      .url("Vui lòng nhập 1 link hợp lệ")
      .required("Vui lòng nhập link ảnh"),
    mobile_image_url: yup
      .string()
      .url("Vui lòng nhập 1 link hợp lệ")
      .required("Vui lòng nhập link ảnh mobile"),
    language: yup.string().required("Vui lòng chọn ngôn ngữ"),
    priority: yup
      .number()
      .integer()
      .min(0, "Vui lòng nhập số lớn hơn 0")
      .max(10, "Vui lòng nhập sô nhỏ hơn 10")
      .required("Vui lòng nhập 1 số từ 1 -> 10"),
    max_action: yup.number().integer().min(0, "Vui lòng nhập số lớn hơn 0"),
  })
  .required();

// /api/campaigns/v1/
