import React from "react";

import IconView from "./IconView";
import IconEdit from "./IconEdit";
import IconRemove from "./IconRemove";
import IconAdd from "./IconAdd";
import { fetcher, snapUserAPI } from "../../config";
import useSWR from "swr";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";

const TableProjects = ({ children, ...props }) => {
  const navigate = useNavigate();
  const [showCampaign, setShowCampaign] = React.useState(null);
  const { data, error, isLoading } = useSWR(snapUserAPI.projects(), fetcher);
  const handleToggleCampaign = (id) => {
    showCampaign === id ? setShowCampaign(null) : setShowCampaign(id);
  };
  console.log(data);
  console.log(showCampaign);
  return (
    <div className="prose">
      {isLoading && <div className="mt-3">Đang tải dữ liệu...</div>}
      {!isLoading && data.data.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Tên dự án</th>
              <th>Số lượng từ khóa</th>
              <th>Tìm kiếm trên ngày</th>
              <th>Thao tác</th>
            </tr>
          </thead>
          <tbody>
            {data.data.map((item) => {
              return (
                <>
                  <tr key={uuidv4()}>
                    <td className="flex items-center gap-2">
                      <button
                        className="flex items-center justify-center w-4 h-4 p-2 border border-gray-300 rounded"
                        onClick={() => handleToggleCampaign(item._id)}
                      >
                        {showCampaign === item._id ? "-" : "+"}
                      </button>
                      <span
                        onClick={() => navigate(`/project/${item._id}`)}
                        className="cursor-pointer"
                      >
                        {" "}
                        {item.name}
                      </span>
                    </td>
                    <td>{item.campaigns.length}</td>
                    <td>{item.max_action}</td>
                    <td className="flex items-center gap-2">
                      <button onClick={() => navigate(`/project/${item._id}`)}>
                        <IconView></IconView>
                      </button>
                      <button
                        onClick={() => navigate(`/project/${item._id}/edit`)}
                      >
                        <IconEdit></IconEdit>
                      </button>
                      {/* <IconRemove></IconRemove> */}
                      <button
                        onClick={() => navigate(`/keyword/add/${item._id}`)}
                      >
                        <IconAdd></IconAdd>
                      </button>
                    </td>
                  </tr>
                  {showCampaign === item._id && (
                    <ShowCamp item={item}></ShowCamp>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      )}
      {error && <div>Lỗi: không lấy được dữ liệu</div>}
    </div>
  );
};

const ShowCamp = ({ item }) => {
  if (item.campaigns && item.campaigns.length === 0) {
    return (
      <tr key={uuidv4()}>
        <td className="text-red-500">Không có keyword</td>
      </tr>
    );
  }
  console.log(item);
  return (
    <>
      {item.campaigns &&
        item.campaigns.length > 0 &&
        item.campaigns.map((campain, index) => (
          <tr key={uuidv4()} className="text-sm font-light bg-slate-100">
            <td>{`${index + 1}. ${campain.keyword}`}</td>
            <td></td>
            <td>{`${campain.completed_action} / ${campain.max_action}`}</td>
            <td className="flex items-center gap-2"></td>
          </tr>
        ))}
    </>
  );
};

export default TableProjects;
