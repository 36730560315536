import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../../firebase/firebase-config";
import FirebaseAuth from "../../firebase/Firebase-Auth";
import { toast } from "react-toastify";

const Main = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = React.useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserInfo(user);
      } else {
        setUserInfo(null);
        // User is signed out
        // ...
        console.log("user is logged out");
        navigate("/login");
      }
    });
  }, []);
  const handleSignOut = async (e) => {
    e.preventDefault();
    await signOut(auth)
      .then(() => {
        // Sign-out successful.
        toast.success("Signed out successfully");
        setUserInfo(null);
      })
      .catch((error) => {
        // An error happened.
        console.log(error);
      });
  };
  console.log(userInfo);
  if (userInfo === null) return <Fragment></Fragment>;
  return (
    <Fragment>
      <div className="md:flex">
        <Sidebar
          handleSignOut={handleSignOut}
          displayName={userInfo?.displayName || userInfo?.email || ""}
        ></Sidebar>
        <div className="flex flex-col flex-1 px-4 mt-16">
          {/* <FirebaseAuth></FirebaseAuth> */}
          <Outlet></Outlet>
        </div>
      </div>
    </Fragment>
  );
};

export default Main;
