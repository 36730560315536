import React from "react";
import { Link } from "react-router-dom";
import TableKeywords from "../components/table/TableKeywords";

const KeywordPage = () => {
  return (
    <>
      <div>
        <input
          type="text"
          className="w-full p-3 border border-gray-300 rounded"
          placeholder="Search..."
        />
        <TableKeywords></TableKeywords>
      </div>
    </>
  );
};

export default KeywordPage;
