import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetcher, snapUserAPI } from "../config";
import useSWR from "swr";
import Input from "../components/input/Input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";

const EditProjectPage = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const schema = yup
    .object({
      project_name: yup
        .string()
        .required("Vui lòng nhập project name")
        .min(5, "Tên dự án tối thiểu 5 ký tự")
        .max(50, "Tên dự án tối đa 50 ký tự"),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = (data) => {
    if (!isValid) return;
    console.log(data);
    axios
      .put(snapUserAPI.getProject(projectId), data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        if (response.data.status === 0) {
          toast.success("Chỉnh sửa dự án thành công");
          navigate("/");
        } else {
        }
      })
      .catch(function (error) {
        toast.error("Chỉnh sửa thất bại");
      });
  };

  const { data, error, isLoading } = useSWR(
    snapUserAPI.getProject(projectId),
    fetcher
  );

  if (error) {
    console.log(error);
  }
  return (
    <div>
      {data && (
        <form
          className="grid grid-cols-1 gap-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col w-full gap-2">
            <label htmlFor="project_name" className="cursor-pointer">
              Tên dự án
            </label>
            <Input
              type="text"
              name="project_name"
              id="project_name"
              placeholder="Vui lòng nhập tên dự án"
              control={control}
              defaultValue={data.data.name || ""}
            ></Input>

            {errors.project_name && (
              <p className="text-sm italic text-red-500">
                {errors.project_name.message}
              </p>
            )}
          </div>
          <button
            type="submit"
            className={`p-4 w-full bg-blue-500 text-white rounded-md mt-5 font-semibold flex justify-center items-center ${
              isSubmitting ? "opacity-50" : ""
            }`}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <div className="w-5 h-5 border-2 border-white rounded-full border-r-transparent animate-spin"></div>
            ) : (
              "Update"
            )}
          </button>
        </form>
      )}
    </div>
  );
};

export default EditProjectPage;
