// import { Route, Routes } from "react-router-dom";
import React from "react";
import "./App.scss";
import { Route, Routes } from "react-router";
import Main from "./components/layout/Main";
import HomePage from "./pages/HomePage";
import KeywordPage from "./pages/KeywordPage";
import AddProjectPage from "./pages/AddProjectPage";
import AddKeywordPage from "./pages/AddKeywordPage";
import EditProjectPage from "./pages/EditProjectPage";
import ProjectDetailPage from "./pages/ProjectDetailPage";
import EditKeywordPage from "./pages/EditKeywordPage";
import FirebaseAuth from "./firebase/Firebase-Auth";

function App() {
  return (
    <Routes>
      <Route element={<Main></Main>}>
        <Route path="/" element={<HomePage></HomePage>} />
        <Route
          path="/project/add"
          element={<AddProjectPage></AddProjectPage>}
        />
        <Route
          path="/project/:projectId/edit"
          element={<EditProjectPage></EditProjectPage>}
        />
        <Route
          path="/project/:projectId"
          element={<ProjectDetailPage></ProjectDetailPage>}
        />
        <Route path="/keyword" element={<KeywordPage></KeywordPage>} />
        <Route
          path="/keyword/add/:id"
          element={<AddKeywordPage></AddKeywordPage>}
        />
        <Route
          path="/keyword/edit/:projectId/:campaignId"
          element={<EditKeywordPage></EditKeywordPage>}
        />
      </Route>
      <Route path="/login" element={<FirebaseAuth></FirebaseAuth>} />
      <Route path="*" element={<>404 not found</>} />
    </Routes>
  );
}

export default App;
