import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCqdavBrpCxpYMY7iDLcE3ptEqF4S01WUA",
  authDomain: "snapuser-51f85.firebaseapp.com",
  projectId: "snapuser-51f85",
  storageBucket: "snapuser-51f85.appspot.com",
  messagingSenderId: "393264837182",
  appId: "1:393264837182:web:6575b01a26f023eaca176f",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Ini service
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
