import React from "react";
import TableProjects from "../components/table/TableProjects";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <>
      <div>
        {/* <input
          type="text"
          className="w-full p-3 border border-gray-300 rounded"
          placeholder="Search..."
        /> */}
        <Link
          to="/project/add"
          className="inline-block px-3 py-2 mt-4 text-white bg-blue-500 rounded"
        >
          Thêm dự án
        </Link>
      </div>
      <TableProjects></TableProjects>
    </>
  );
};

export default HomePage;
