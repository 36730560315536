import React from "react";

import IconView from "./IconView";
import IconEdit from "./IconEdit";
import IconRemove from "./IconRemove";
import IconAdd from "./IconAdd";
import { fetcher, snapUserAPI } from "../../config";
import useSWR from "swr";
import { useNavigate } from "react-router-dom";

const TableKeywords = ({ children, ...props }) => {
  const navigate = useNavigate();
  const { data, error, isLoading } = useSWR(
    snapUserAPI.getCampaigns(),
    fetcher
  );
  console.log(data);
  return (
    <div className="prose">
      {isLoading && <div className="mt-3">Đang tải dữ liệu...</div>}
      {!isLoading && data.data.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>STT</th>
              <th>Từ khóa</th>
              <th>Ngôn ngữ</th>
              <th>Độ ưu tiên</th>
              <th>Sl Real/Max</th>
              <th>Volume max</th>
              <th>Thao tác</th>
            </tr>
          </thead>
          <tbody>
            {data.data.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.keyword}</td>
                <td>{item.language}</td>
                <td>{item.priority}</td>
                <td>{`${item.completed_action} / ${item.max_action}`}</td>
                <td>{item.max_action}</td>
                <td className="flex items-center gap-2">
                  <button
                    onClick={() =>
                      navigate(`/keyword/edit/${item.project_id}/${item._id}`)
                    }
                  >
                    <IconEdit></IconEdit>
                  </button>
                  <button
                    onClick={() => alert("Tính năng đang được phát triển")}
                  >
                    <IconRemove></IconRemove>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {error && <div>Lỗi: không lấy được dữ liệu</div>}
    </div>
  );
};

export default TableKeywords;
