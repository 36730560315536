import React from "react";

export default function useClickOutSide(dom = "button") {
  const [isOpen, setIsOpen] = React.useState(false);
  const nodeRef = React.useRef(null);
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (
        nodeRef.current &&
        !nodeRef.current.contains(event.target) &&
        !event.target.matches(dom)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dom]);
  return { isOpen, setIsOpen, nodeRef };
}
