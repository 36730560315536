import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  getAuth,
  onAuthStateChanged,
  updateProfile,
} from "firebase/auth";
import { auth, db } from "./firebase-config";
import { addDoc, collection } from "firebase/firestore";
import { toast } from "react-toastify";

const FirebaseAuth = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [userInfo, setUserInfo] = React.useState(null);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserInfo(user);
      } else {
        setUserInfo(null);
        // User is signed out
        // ...
        console.log("user is logged out");
      }
    });
  }, []);
  const handleSignOut = async (e) => {
    e.preventDefault();
    await signOut(auth)
      .then(() => {
        // Sign-out successful.
        console.log("Sign-out successful.");
        setUserInfo(null);
      })
      .catch((error) => {
        // An error happened.
        console.log(error);
      });
  };
  // Login
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const cred = await signInWithEmailAndPassword(auth, email, password);
      setUserInfo(cred);
      toast.success("Đăng nhập thành công");
      navigate("/");
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      setErrorMsg(errorMessage);
      toast.error(errorMessage);
    }
  };

  return (
    <div className="max-w-[500px] mx-auto mt-4 full-full p-3">
      <div className="w-full p-3 mb-10 bg-white rounded shadow-lg">
        <form onSubmit={handleLogin}>
          <input
            type="text"
            className="w-full p-3 mb-5 border border-gray-200 rounded outline-none focus:border-blue-500"
            placeholder="Enter your email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            className="w-full p-3 mb-5 border border-gray-200 rounded outline-none focus:border-blue-500"
            placeholder="Enter your password"
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {errorMsg && <p className="mb-3 text-red-500">{errorMsg}</p>}

          <button
            type="submit"
            className="w-full p-3 text-white bg-blue-600 rounded"
          >
            Đăng nhập
          </button>
        </form>
      </div>
    </div>
  );
};

export default FirebaseAuth;
