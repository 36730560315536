import React from "react";
import useSWR from "swr";
import { fetcher, snapUserAPI } from "../config";
import Input from "../components/input/Input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddProjectPage = () => {
  const navigate = useNavigate();
  const schema = yup
    .object({
      name: yup
        .string()
        .required("Vui lòng nhập project name")
        .min(5, "Project name phải có ít nhất 5 ký tự")
        .max(30, "Project name phải ngắn hơn 30 ký tự"),
    })
    .required();
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors, isValid, isSubmitting, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      name: "",
    },
  });
  let errorMsg = "";
  const onSubmit = (data) => {
    if (!isValid) return;
    const qs = "?" + new URLSearchParams(data).toString();
    axios
      .post(`https://snapuser.snapx.info/api/projects/v1/${qs}`)
      .then(function (response) {
        if (response.data.status === 0) {
          toast.success("Thêm dự án mới thành công");

          navigate("/");
        } else {
          toast.error("Thêm dự án mới thất bại");
          errorMsg = "1";
        }
      })
      .catch(function (error) {
        toast.error("Thêm dự án mới thất bại. Kiểm tra lại api");
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="mb-2 text-2xl font-semibold">Thêm dự án mới</h1>
      <label htmlFor="project-name">Tên dự án</label>
      {/* <input
        id="project-name"
        type="text"
        className="w-full p-3 mt-2 border border-gray-300 rounded outline-none focus:border-blue-500"
        placeholder="Nhập tên dự án"
        name="name"
        onChange={(e) => setName(e.target.value)}
      /> */}
      <Input
        type="text"
        name="name"
        id="name"
        placeholder="Nhập tên dự án"
        control={control}
      ></Input>
      {errorMsg && <div className="text-red-500">Thêm dự án mới thất bại</div>}

      {errors.name && (
        <p className="text-sm italic text-red-500">{errors.name.message}</p>
      )}
      <button
        className="px-6 py-2 mt-4 text-white bg-blue-500 rounded"
        type="submit"
      >
        Thêm dự án
      </button>
    </form>
  );
};

export default AddProjectPage;
