import React from "react";
import Input from "../components/input/Input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Dropdown from "../components/dropdown/Dropdown";
import { fetcher, languages, schemaCampaign, snapUserAPI } from "../config";
import Required from "../components/template/Required";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { toast } from "react-toastify";

const AddKeywordPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data, error, isLoading } = useSWR(
    snapUserAPI.getProject(id),
    fetcher
  );

  if (error) {
    console.log(error);
  }

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors, isValid, isSubmitting, isSubmitSuccessful },
  } = useForm({
    resolver: yupResolver(schemaCampaign),
    mode: "onChange",
  });

  const onSubmit = (data) => {
    if (!isValid) return;
    console.log(data);
    axios
      .post(snapUserAPI.createKeyword(id), data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        if (response.data.status === 0) {
          toast.success("Thêm keyword mới thành công");
          navigate(`/project/${id}`);
        } else {
          toast.error("Thêm từ khóa mới thất bại");
        }
      })
      .catch(function (error) {
        toast.error("Thêm từ khóa mới thất bại");
      });
  };
  console.log(data);
  return (
    <div>
      {data && data.data.name && (
        <h1 className="mb-2 text-xl font-semibold">
          Thêm từ khóa vào: {data.data.name}
        </h1>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <label htmlFor="keyword" className="cursor-pointer">
              Từ khóa <Required />
            </label>
            <Input
              type="text"
              name="keyword"
              id="keyword"
              placeholder="Nhập từ khóa"
              control={control}
            ></Input>

            {errors.keyword && (
              <p className="text-sm italic text-red-500">
                {errors.keyword.message}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="website" className="cursor-pointer">
              website <Required />
            </label>
            <Input
              type="text"
              name="website"
              id="website"
              placeholder="Enter website"
              control={control}
            ></Input>

            {errors.website && (
              <p className="text-sm italic text-red-500">
                {errors.website.message}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="image_url" className="cursor-pointer">
              Hình ảnh <Required />
            </label>
            <Input
              type="text"
              name="image_url"
              id="image_url"
              placeholder="Nhập link hình ảnh"
              control={control}
            ></Input>

            {errors.image_url && (
              <p className="text-sm italic text-red-500">
                {errors.image_url.message}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="mobile_image_url" className="cursor-pointer">
              Hình ảnh mobile <Required />
            </label>
            <Input
              type="text"
              name="mobile_image_url"
              id="mobile_image_url"
              placeholder="Nhập link hình ảnh mobile"
              control={control}
            ></Input>

            {errors.mobile_image_url && (
              <p className="text-sm italic text-red-500">
                {errors.mobile_image_url.message}
              </p>
            )}
          </div>

          <div className="flex flex-col gap-2 mt-4">
            <label htmlFor="language" className="cursor-pointer">
              Ngôn ngữ - Local hiển thị <Required />
            </label>
            <Dropdown
              control={control}
              setValue={setValue}
              name="language"
              data={languages}
              dropdownLabel="Country"
            ></Dropdown>

            {errors.language && (
              <p className="text-sm italic text-red-500">
                {errors.language.message}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="priority" className="cursor-pointer">
              Độ ưu tiên <Required />
            </label>
            <Input
              type="number"
              name="priority"
              id="priority"
              placeholder="Enter priority"
              control={control}
            ></Input>

            {errors.priority && (
              <p className="text-sm italic text-red-500">
                {errors.priority.message}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="max_action" className="cursor-pointer">
              Số lượng traffic <Required />
            </label>
            <Input
              type="number"
              name="max_action"
              id="max_action"
              placeholder="Nhập số lượng traffic"
              control={control}
            ></Input>

            {errors.max_action && (
              <p className="text-sm italic text-red-500">
                {errors.max_action.message}
              </p>
            )}
          </div>
        </div>
        <button
          type="submit"
          className={`p-4 w-full bg-blue-500 text-white rounded-md mt-5 font-semibold flex justify-center items-center ${
            isSubmitting ? "opacity-50" : ""
          }`}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <div className="w-5 h-5 border-2 border-white rounded-full border-r-transparent animate-spin"></div>
          ) : (
            "Thêm từ khóa mới"
          )}
        </button>
      </form>
    </div>
  );
};

export default AddKeywordPage;
