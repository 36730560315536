import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

const Sidebar = ({ handleSignOut, displayName = "" }) => {
  const navigate = useNavigate();
  const navlinks = [
    { id: 1, name: "Dự án", path: "/" },
    { id: 2, name: "Từ khóa", path: "/keyword" },

    // { id: 3, name: "Movies Detail", path: "/movie/xin-chao" },
  ];

  const [showSlidebar, setShowSidebar] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setShowSidebar(true);
      } else {
        setShowSidebar(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toggleSidebar = () => setShowSidebar(!showSlidebar);
  return (
    <>
      <div
        className={`sidebar max-h-screen top-0 h-screen bg-gray-800 text-blue-100 w-64 fixed inset-y-0 left-0 transition-all z-50 ${
          showSlidebar ? "md:translate-x-0 md:sticky " : "-translate-x-full"
        }`}
      >
        <header className="h-[64px] py-2 shadow-lg px-4 md:sticky top-0 bg-gray-800 z-40 flex items-center relative">
          <div
            className="flex items-center space-x-2 text-white cursor-pointer group hover:text-white"
            onClick={() => navigate("/")}
          >
            <span className="text-2xl font-extrabold">SNAPUSER</span>
          </div>
          <div
            className="absolute flex items-center justify-center w-10 h-10 p-1 translate-x-full bg-gray-800 rounded-full cursor-pointer -right-4 top-4 md:hidden"
            onClick={toggleSidebar}
          >
            {!showSlidebar && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                ></path>
              </svg>
            )}
            {showSlidebar && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            )}
          </div>
        </header>
        <nav className="px-4 pt-4 scroller overflow-y-scroll max-h-[calc(100vh-64px)]">
          <ul className="flex flex-col space-y-2">
            {navlinks &&
              navlinks.map((link) => (
                <li className="text-gray-200 " key={link.id}>
                  <NavLink
                    to={link.path}
                    className={({ isActive }) =>
                      (isActive ? "bg-gray-700" : "") +
                      " relative flex items-center w-full px-2 py-2 rounded hover:text-white hover:bg-gray-700"
                    }
                  >
                    {link.name}
                  </NavLink>
                </li>
              ))}
          </ul>
          <div className="w-full h-[1px] bg-slate-700 mb-4"></div>
          <div className="flex items-center gap-4">
            <span>{displayName}</span>
            <button
              className="flex items-center justify-center block w-8 h-8 bg-gray-700 rounded"
              onClick={handleSignOut}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                />
              </svg>
            </button>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
